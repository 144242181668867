const colors = {
  white: '#FFF',
  blazeOrange: '#FE6301',
  mantis: '#69C779',
  alto: '#D8D8D8',
  dustyGray: '#979797',
  lightSkyBlue: '#87CEFA'
};

export default colors;
